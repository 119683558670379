@tailwind base;
@layer base {
img, canvas {
@apply inline-block;
}
}
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Urbanist&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Urbanist:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
  width: 100vw;
  height: 100vh;
}

.sideMargin {
  @apply px-4 lg:px-8 2xll:px-10 3xxl:px-12;
}
.topSpace {
  @apply pt-[100px] ;
}

.text20 {
  @apply text-xl lg:text-lg 2xll:text-xl;
}

.text24 {
  @apply text-base lg:text-lg 2xll:text-xl;
}

.text28 {
  @apply text-xl lg:text-2xl 2xll:text-[28px];
}

.text32 {
  @apply text-1.5xl lg:text-xl 2xll:text-3xl;
}

.text36 {
  @apply text-2xl 2xll:text-3xl 3xxl:text-3xl;
}

.text42 {
  @apply text-2.5xl 2xll:text-3.5xl;
}

.text30{
  @apply text-[12px] md:text-[20px] lg:text-[20px] 2xll:text-[24px] 3xxl:text-[28px]
}

body {
  .backdrop-blur-md {
    backdrop-filter: blur(8px); /* Adjust the blur value as needed */
  }
  font-family: "Urbanist", sans-serif;
  /* font-family: 'Inter' , serif; */

}

.video-container {
  width: 400px;
  height: 550px;
  overflow: hidden;
  border-radius: 10px;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bgadd {
  background: rgba(245, 245, 245, 0.33);
  backdrop-filter: blur(6px);
}

.ease-in-out {
  transition: all 0.3s ease-in-out; /* Adjust the duration as needed */
}

.eee {
  border: 10px solid transparent; /* Set the width of the border */
  border-image: linear-gradient(
    white 25%,
    transparent 25%,
    transparent 100%,
    ""
  );
  border-image-slice: 1; /* Ensure the gradient is applied to the entire border */
}

.hori {
  width: 1px;

  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.gradient-border {
  border-width: 2px transparent;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      #c597ff,
      #a067e9,
      #f3ecfc,
      #9853ee,
      #e4ceff,
      #9141f6
    )
    1;
  border-radius: 10px;
}

.gradient-border::after {
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: linear-gradient(
    to right,
    #c597ff,
    #a067e9,
    #f3ecfc,
    #9853ee,
    #e4ceff,
    #9141f6
  );
  content: "";
  z-index: -1;
  border-radius: 10px;
}

.bgCoupon {
  background: linear-gradient(
      to right,
      #c597ff,
      #a067e9,
      #f3ecfc,
      #9853ee,
      #e4ceff,
      #9141f6
    )
    1;
}


.blockBorder {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

}


::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: 'transparent' !important;
  height:'0px' !important;
  width:'0px'!important;

}



.container2 {
  flex-direction: column;
  display: flex;
  gap: 16px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto !important;
  align-items: center;
  background:'red'
}


nav {
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
}



/* Enable scrollbars for the modal */
.modal-scroll {
  overflow-y: auto; /* Add vertical scrolling */
}

.modal-scroll::-webkit-scrollbar {
  width: 8px; /* Customize the scrollbar width */
  background: #f0f0f0; /* Optional: scrollbar background color */
}

.modal-scroll::-webkit-scrollbar-thumb {
  background: #888; /* Customize scrollbar thumb */
  border-radius: 4px; /* Optional: rounded scrollbar thumb */
}

.modal-scroll::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}
